@import "../../scss/mixin.scss";

.hero {
  width: 100vw;
  height: 100vh;
  content: " ";
  background-image: url("../../../public/images/1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    background-size: cover;
    background-position: 50% 50%;
  }
}
.hero-img{
  max-width: calc(100vw - 6rem);
}

.text-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.image{
  opacity: 0;    
  transition: opacity 1s ease-in-out;
  &.active{
    opacity: 1;
  }
}
.phrase {
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-bottom: 10px;
}

.phrase.active {
  opacity: 1;
}
.copy {
  width: 100%;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mobile {
    padding: 3rem 1.5rem;
  }
  h1 {
    width: 50%;
    font-size: 44px;
    text-transform: uppercase;
    padding: 3rem 0 4.5rem 0;
    text-align: center;
    color: $green;
    line-height: 57px;
    @include mobile {
      width: 100%;
      font-size: 28px;
      padding: 1.5rem 0 1.5rem 0;
      line-height: 40px;
    }
  }
  p {
    text-align: center;
    width: 55%;
    font-family: 'Verona';
    font-weight: normal;
    font-size: 24px;
    padding-bottom: 3rem;
    color: $green;
    line-height: 28px;
    @include mobile {
      padding: 1.5rem 1.5rem;
      width: 100%;
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.highlights {
  display: flex;
  width: 100%;
  padding: 3rem 0 6rem 0;
  justify-content: space-evenly;
  @include mobile {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
  }
  .highlight:first-child{
    margin-top: 0;
  }
  .highlight {
    border: 1px solid $green;
    display: flex;
    flex-direction: column;
    max-width: 28%;
    height: 100%;
    @include mobile {
      max-width: 100%;
      width: 100%;
      margin: 1.5rem 0;
    }
    .img-holder {
      width: 100%;
      height: 50vh;
      background-size: cover;
      background-position: 28%;
    }
    .highlight-copy {
      height: 250px;
      padding: 2rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      h3 {
        font-size: 19px;
        line-height: 22.8px;
        padding: 0rem 0;
        width: 75%;
        text-transform: uppercase;
        font-family: 'Verona';
        font-weight: 500;
        color: $green;
      }
      p {
        padding: 1.5rem 0 3rem 0;
        font-family: 'Verona';
        font-weight: normal;
        color: $green;
        width: 80%;
      }
    }
  }
}

.border {
  width: 100%;
  max-width: 100vw;
  height: 150px;
  background-image: url("../../../public/images/2.jpg");
  background-position: 50% 50%;
  background-size: cover;
  content: " ";
  overflow: hidden;
}

.copy-two{
  width: 100%;
  display: flex;
  justify-content: center;
  p{
    text-align: center;
    width: 54%;
    font-family: 'Verona';
    font-weight: normal;
    font-size: 24px;
    padding: 6rem 0 3rem; 
    color: $green;
    line-height: 28px;
    @include mobile {
      padding: 3rem 1.5rem 1.5rem 1.5rem;
      width: 90%;
      font-size: 18px;
      line-height: 25px;
    }
  }
}